var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.styleType === "button"
        ? _c(
            "div",
            {
              staticClass: "supplier_actions_btns has_two_btn",
              attrs: { id: "payrollCode" },
              on: { click: _vm.handleClickManagePayrollBtn },
            },
            [
              _c(
                "b-button",
                [
                  _c(_vm.getLucideIcon("Cog"), {
                    tag: "component",
                    attrs: { color: "#FFFFFF", size: 20 },
                  }),
                ],
                1
              ),
              _c(
                "b-button",
                { attrs: { block: "", variant: "outline-primary" } },
                [_vm._v(_vm._s(_vm.FormMSG(25, "Manage payroll codes...")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.styleType === "select"
        ? _c("v-select", {
            staticClass: "style-payroll-code",
            attrs: {
              options: _vm.pCodes,
              disabled: _vm.disabled,
              label: "code",
              reduce: (option) => option.id,
              clearable: _vm.selectClearable,
              placeholder: _vm.FormMSG(449, "Payroll code ..."),
            },
            on: { input: _vm.handleInputSelectPayrollCode },
            scopedSlots: _vm._u(
              [
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(_vm.FormMSG(468, "No payroll code found")) +
                          "\n\t\t"
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3892418777
            ),
            model: {
              value: _vm.payrollCodeSelected,
              callback: function ($$v) {
                _vm.payrollCodeSelected = $$v
              },
              expression: "payrollCodeSelected",
            },
          })
        : _vm._e(),
      _vm.styleType === "button"
        ? _c(
            "div",
            [
              _c(
                "b-modal",
                {
                  attrs: {
                    title: _vm.FormMSG(79, "Payroll code list"),
                    "header-class": "header-class-modal-doc-package",
                    size: "lg",
                    "no-close-on-esc": "",
                    "no-close-on-backdrop": "",
                    "hide-header-close": "",
                    "cancel-variant": "outline-primary",
                    "cancel-title": _vm.FormMSG(81, "Close"),
                    "ok-title": _vm.FormMSG(80, "Add new code"),
                    "modal-class": "mui-animation",
                    fade: false,
                  },
                  on: {
                    ok: function ($event) {
                      $event.preventDefault()
                      return _vm.handleClickAddPayrollCode.apply(
                        null,
                        arguments
                      )
                    },
                    hidden: _vm.closeModal,
                    cancel: _vm.closeModal,
                  },
                  model: {
                    value: _vm.showModal,
                    callback: function ($$v) {
                      _vm.showModal = $$v
                    },
                    expression: "showModal",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
                            [
                              _vm.$screen.width >= 992
                                ? _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.FormMSG(
                                            65,
                                            "Type to search"
                                          ),
                                        },
                                        model: {
                                          value: _vm.filter,
                                          callback: function ($$v) {
                                            _vm.filter = $$v
                                          },
                                          expression: "filter",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c(
                                            "b-input-group-text",
                                            { staticClass: "btn-search" },
                                            [
                                              _vm.filter.length === 0
                                                ? _c(
                                                    _vm.getLucideIcon("Search"),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#FFFFFF",
                                                        size: 16,
                                                        "stroke-width": 2.5,
                                                      },
                                                    }
                                                  )
                                                : _c(_vm.getLucideIcon("X"), {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#FFFFFF",
                                                      size: 16,
                                                      "stroke-width": 2.5,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.filter = ""
                                                      },
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _vm.$screen.width < 992
                                ? _c("CardListBuilder", {
                                    attrs: {
                                      items: _vm.pCodes,
                                      fields: _vm.fields,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "actions",
                                          fn: function (data) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "mt-3" },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant: "danger",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleClickDeleteItem(
                                                            data.item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Trash2"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: { size: 16 },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1824415570
                                    ),
                                  })
                                : _vm._e(),
                              _vm.$screen.width >= 992
                                ? _c("b-table", {
                                    ref: "payrollTable",
                                    staticStyle: { "text-align": "left" },
                                    attrs: {
                                      "selected-variant": "primary",
                                      hover: "",
                                      selectable: "",
                                      "select-mode": "single",
                                      responsive: "sm",
                                      "sticky-header": "700px",
                                      items: _vm.tpCodes,
                                      fields: _vm.fields,
                                      filter: _vm.filter,
                                      bordered: "",
                                      small: "",
                                      "head-variant": "dark",
                                      "empty-text": _vm.FormMSG(
                                        158,
                                        "No payroll code found"
                                      ),
                                      "show-empty": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(code)",
                                          fn: function (data) {
                                            return [
                                              _c("col", {
                                                staticStyle: { width: "160px" },
                                              }),
                                              _c("inline-input", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  data: data,
                                                  placeholder: _vm.FormMSG(
                                                    2,
                                                    "Your code ..."
                                                  ),
                                                  "id-name": _vm.EL_NAME_CODE,
                                                  "value-attribute": "code",
                                                  isSubmitted: _vm.isSubmitted,
                                                  error: _vm.$v.payCode.$error,
                                                },
                                                on: {
                                                  "payroll-code-inline-input:valid-data":
                                                    _vm.removeConstraint,
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(description)",
                                          fn: function (data) {
                                            return [
                                              _c("col", {
                                                staticStyle: { width: "160px" },
                                              }),
                                              _c("inline-input", {
                                                attrs: {
                                                  data: data,
                                                  placeholder: _vm.FormMSG(
                                                    3,
                                                    "Your description ..."
                                                  ),
                                                  "id-name": _vm.EL_NAME_DESC,
                                                  "value-attribute":
                                                    "description",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(edit)",
                                          fn: function (data) {
                                            return [
                                              _c("inline-button", {
                                                attrs: { data: data },
                                                on: {
                                                  "payroll-code-inline-button:updated":
                                                    function ($event) {
                                                      return _vm.handleUpdate(
                                                        data
                                                      )
                                                    },
                                                  "payroll-code-inline-button:saved":
                                                    function ($event) {
                                                      return _vm.handleSave(
                                                        data.item.id
                                                      )
                                                    },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(remove)",
                                          fn: function (data) {
                                            return [
                                              _c("div", [
                                                data.item.id > 0
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn-transparent text-danger",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleClickDeleteItem(
                                                              data.item.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            "Trash2"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              size: 20,
                                                              "stroke-width": 2,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1855934714
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }